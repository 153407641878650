import { Trans, useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useState, useEffect } from "react";
import AppFeatureImage from "../../../components/AppFeatureImage";
import PopUpFeature from "../../../components/PopUpFeature";
import ImgDownload from "../../../components/ImgDownload";

const AppFeaturePage = () => {
  const { t } = useTranslation()
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  const [isVisiable, setIsVisiable] = useState(false);


  useEffect(() => {
    window.addEventListener("scroll", function () {

      const maxHeight = document.body.scrollHeight - window.innerHeight;
      var scroll = (window.pageYOffset * 100) / maxHeight;
      if (scroll > 15) {
        setIsVisiable(true);
      } else {
        setIsVisiable(false);
      }
    });

  }, []);

  useEffect(() => {
    setLang(i18n.language.split("-")[0]);
  }, [i18n.language]);

  return (
    <>
      <div className="grid relative h-auto place-items-center overflow-hidden bg-disabled-5 w-full text-primary-1000 text-center  text-mobile-body-xl-400 sm:text-body-xl-400">
        <div className="relative pt-7 pb-8 ">
          <div class="w-full inline-flex flex-nowrap">
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-left">
              <li>
                {lang === "es" ? <img src={require("../../../images/row_es.png")} alt="" /> : <img src={require("../../../images/row.png")} alt="" />}
              </li>
            </ul>
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-left" aria-hidden="true">
              <li>
                {lang === "es" ? <img src={require("../../../images/row_es.png")} alt="" /> : <img src={require("../../../images/row.png")} alt="" />}
              </li>
            </ul>
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-left" aria-hidden="true">
              <li>
                {lang === "es" ? <img src={require("../../../images/row_es.png")} alt="" /> : <img src={require("../../../images/row.png")} alt="" />}
              </li>
            </ul>
          </div>
          <div class="w-full inline-flex flex-nowrap">
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-right" aria-hidden="true">
              <li>
                {lang === "es" ? <img src={require("../../../images/row-1_es.png")} alt="" /> : <img src={require("../../../images/row-1.png")} alt="" />}
              </li>
            </ul>
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-right" >
              <li>
                {lang === "es" ? <img src={require("../../../images/row-1_es.png")} alt="" /> : <img src={require("../../../images/row-1.png")} alt="" />}
              </li>
            </ul>
            <ul class="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll-right" >
              <li>
                {lang === "es" ? <img src={require("../../../images/row-1_es.png")} alt="" /> : <img src={require("../../../images/row-1.png")} alt="" />}
              </li>
            </ul>
          </div>
        </div>
        <div className="px-6 py-8 bg-neutral-0 rounded-20 max-w-2xl absolute shadow-feature_hero w-auto mx-6 bottom-4 sm:mx-0 sm:bottom-40 sm:top-40">
          <p className="text-mobile-body-xl-400 sm:text-body-xl-400 ">
            <Trans components={{
              b: <b />,
              p: <p />,
            }}>{t("app_feature.header")}</Trans>
          </p>
          <ImgDownload theme={"feature"} />
        </div>
      </div>

      <div className="text-dark pb-20 flex sm:grid sm:grid-cols-12">
        <section className="col-start-2 pt-20 pb-10 hidden sm:block sm:col-end-12 md:col-end-8">
          <article>
            <h1 className="text-mobile-heading-h1 sm:text-heading-h1">{t("app_feature.title")}</h1>
            <p className="text-mobile-body-xl-400 pt-10 pl-20 sm:text-body-xl-400">{t("app_feature.description")}</p>
          </article>
        </section>

        <div className="col-start-3 col-span-11 gap-14 px-6 sm:px-0">
          <section className="grid text-dark grid-flow-row pb-0 pt-5 sm:grid-flow-col sm:pt-10 sm:pb-10">
            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-1_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-1.png")} />}

            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-2_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-2.png")} />}
          </section>

          <section className="grid text-dark grid-flow-row pb-0 pt-0 sm:grid-flow-col sm:pt-10 sm:pb-10">
            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-3_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-3.png")} />}


            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-4_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-4.png")} />}
          </section>

          <section className="grid text-dark grid-flow-row pb-0 pt-0 sm:grid-flow-col sm:pt-10 sm:pb-10">
            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-5_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-5.png")} />}

            {lang == "es" ? <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-6_es.png")} /> : <AppFeatureImage title={t("app_feature.skin")} description={t("app_feature.skin_description")} image={require("../../../images/feature-6.png")} />}
          </section>
        </div>
      </div>

      <PopUpFeature isVisible={isVisiable} />
    </>
  );
}

export default AppFeaturePage;