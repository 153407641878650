import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as IconClose } from "../images/icon-close-circle.svg";
import ImgDownload from "./ImgDownload";

const PopUpFeature = ({ isVisible }) => {
  const { t } = useTranslation();
  const [close, setClose] = useState(false);


  const handlerClosePopUp = () => {
    setClose(true);
  }

  return (
    <div className={`${isVisible ? "fixed animate-fade-left duration-100" : "hidden"} bottom-8 right-8 text-primary-1000 rounded-20 grid bg-neutral-0 px-6 pt-12 pb-10 shadow-dropdown max-w-xl z-20  ${close ? "invisible" : ""} hidden sm:block`}>
      <div className=" grid place-items-center text-center ">
        <div className="text-body-l-400">
          <Trans
            components={{
              b: <b />,
              a: <a />,
              p: <p />,
            }}
          >
            {t("app_feature.header")}
          </Trans>
        </div>
        <ImgDownload theme="popupfeature" />
      </div>
      <button onClick={
        () => handlerClosePopUp()
      } className="absolute top-2 right-2"><IconClose /></button>
    </div>
  );
};

export default PopUpFeature;