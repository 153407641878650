import React from "react";
import { useTranslation } from "react-i18next";
import GetInTouch from "../../../components/GetInTouch";
import { ReactComponent as RectangleRounded } from "../../../images/rectangle-rounded.svg";
import { ReactComponent as SemiCircle } from "../../../images/semi-circle.svg";
import { ReactComponent as RectangleRoundedMobile } from "../../../images/rectangle-rounded-mobile.svg";
import { ReactComponent as SemiCircleMobile } from "../../../images/semi-circle-mobile.svg";
import { ReactComponent as Section3 } from "../../../images/section3.svg";
import AboutHero from "../../../images/about-hero.svg";
import TeamImage from "../../../components/TeamImage";
import Team1 from "../../../images/team1.png";
import Team2 from "../../../images/team2.png";
import Team3 from "../../../images/team3.png";
import Team4 from "../../../images/team4.png";
import Team5 from "../../../images/team5.png";
import Team6 from "../../../images/team6.png";
import Team7 from "../../../images/team7.png";
import Team8 from "../../../images/team8.png";
import Team9 from "../../../images/team9.png";
import Team10 from "../../../images/team10.png";



const AboutPage = () => {
  const { t } = useTranslation();

  let mouseDown = false;
  let startX, scrollLeft;
  const slider = document.querySelector('.parent');

  const startDragging = (e) => {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  }

  const stopDragging = (e) => {
    mouseDown = false;
  }

  const move = (e) => {
    e.preventDefault();
    if (!mouseDown) { return; }
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  }

  return (
    <div className="text-dark">
      {/* SECTION 1 */}
      <section className="grid grid-cols-1 px-6 sm:grid-cols-12 sm:px-0">
        <article className="py-0 pt-12 pb-6 sm:pt-24 sm:pb-0 sm:col-start-2 sm:col-end-12 md:col-end-8">
          <h1 className="text-mobile-heading-h1 sm:text-heading-h1">
            {t("about.title")}
          </h1>
          <p className="text-mobile-body-xl-400 pt-8 pl-20 max-sm:pl-10 sm:text-body-xl-400">
            {t("about.description")}
          </p>
        </article>
        <div className="w-full justify-end flex sm:block sm:col-start-9 sm:col-end-13 md:col-end-13">
          <img src={AboutHero} alt="about-hero" className="object-cover w-1/2 sm:w-5/6" />
        </div>
      </section>
      {/* SECTION 2 COLOR */}
      <div className="grid w-full bg-primary-5 md:grid-cols-12">
        <div className="col-start-3 col-end-11 items-center text-primary-80 pr-4 pt-24 pb-20 px-6 py-12 text-mobile-display-700 sm:text-display-700 md:pt-24 md:py-0 md:pl-20">
          <div className="text-center">
            {t("section3.your_skin")}
          </div>
          <div className="text-center pt-8 text-mobile-body-xl-400 sm:text-body-xl-400">
            <p> - Koru </p>
          </div>
        </div>
        <Section3 className="hidden md:block sm:w-full sm:row-start-2 sm:col-start-1 sm:col-end-4 sm:mb-4 sm:pl-10" />
      </div>

      {/* SECTION 3 */}
      <section className="flex flex-col py-12 px-6 grid-cols-1 sm:grid sm:grid-cols-12 sm:py-24 sm:px-0 sm:gap-10">
        <div className="grid items-center grid-cols-1 py-0 sm:relative sm:grid-cols-8 sm:col-start-2 sm:col-end-12 sm:py-16 md:col-start-3 md:col-end-11">
          <article className="z-10 sm:col-start-1 sm:col-end-7 md:col-end-6">
            <div className="rounded-20 bg-disabled-5 py-6 sm:py-10">
              <h2 className="text-mobile-heading-h2 pl-6 sm:text-heading-h2">
                {t("about.our_values")}
              </h2>
              <p className="text-mobile-body-l-400 pl-14 pt-6 pr-4 sm:pl-20 sm:text-body-l-400">
                {t("about.our_values_description")}
              </p>
            </div>
          </article>
          <div className="absolute end-0 hidden sm:block">
            <RectangleRounded className="ml-0 object-cover sm:ml-14" />
          </div>
        </div>
        <div className="relative z-0 h-40 sm:hidden">
          <RectangleRoundedMobile className="absolute -top-7 right-0 " />
          <SemiCircleMobile className="absolute bottom-0 -left-8" />
        </div>
        <div className="grid items-center mt-6 py-0 grid-cols-1 sm:py-16 sm:grid-cols-8 sm:col-start-2 sm:col-end-12 md:col-start-3 md:col-end-11">
          <article className="col-span-8 z-0 sm:z-10 sm:col-start-3 md:col-start-4">
            <div className="rounded-20 bg-disabled-5 py-6 sm:py-10">
              <h2 className="text-mobile-heading-h2 pl-6 sm:text-heading-h2">
                {t("about.social")}
              </h2>
              <p className="text-mobile-body-l-400 pl-14 pt-6 pr-4 sm:pl-20 sm:text-body-l-400">
                {t("about.social_description")}
              </p>
            </div>
          </article>
          <div className="absolute hidden sm:block">
            <SemiCircle className="object-cover" />
          </div>
        </div>
      </section>

      {/* SECTION 4*/}
      <div className='h-[600px] py-12 bg-disabled-5 text-primary-1000 grid grid-flow-row px-6 sm:pt-16 sm:pb-24 place-items-center'>

        <h3 className='text-mobile-heading-h2 sm:text-heading-h2 items-start'>{t("about.team")}</h3>

        <div className="parent w-full flex overflow-x-scroll items-start sm:pl-16 sm:items-center overflow-hidden" onMouseMove={(e) => move(e)} onMouseDown={(e) => startDragging(e)} onMouseUp={(e) => stopDragging(e)} onMouseLeave={(e) => stopDragging(e)}>
          <TeamImage name={"Elena Martin"} job={t("about.team-1")} image={Team1} />
          <TeamImage name={"Esther Robisco"} job={t("about.team-2")} image={Team2} />
          <TeamImage name={"Sara Salcedo"} job={t("about.team-3")} image={Team3} />
          <TeamImage name={"Ignacio Alonso"} job={t("about.team-4")} image={Team4} />
          <TeamImage name={"Freya Fine"} job={t("about.team-5")} image={Team5} />
          <TeamImage name={"Rosaria Ruggirello"} job={t("about.team-6")} image={Team6} />
          <TeamImage name={"Chiara Tartaglia"} job={t("about.team-7")} image={Team7} />
          {/* <TeamImage name={"Stefano Fatelli"} job={t("about.team-8")} image={Team8} /> */}
          <TeamImage name={"Elisabetta Perosino"} job={t("about.team-9")} image={Team9} />
          <TeamImage name={"Carlos Bazaco"} job={t("about.team-10")} image={Team10} />
        </div>
      </div>

      {/* SECTION 5 */}
      <a id="contact" />
      <GetInTouch />
    </div>
  );
}

export default AboutPage;