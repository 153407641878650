import React from "react";
import { NavLink } from "react-router-dom";


const ButtonWeb = ({ children, onClick, theme = "default", actived, route }) => {
  return (
    <NavLink to={route}>
      <button
        className={`${actived
            ? "bg-neutral-0 border-2 hover:bg-neutral-0 hover:border-secondary-70 border-secondary-70 hover:text-secondary-70 text-secondary-70 rounded-20 whitespace-nowrap px-10 py-4"
            : theme === "white"
              ? "bg-white text-secondary-70 border border-secondary-70 hover:text-secondary-70 hover:border-secondary-50 active:text-secondary-50 active:border-secondary-50 px-10 py-4"
              : theme === "full"
                ? " bg-secondary-70 hover:bg-secondary-50 hover:border-secondary-50 active:bg-primary-40 active:border-secondary-50 text-neutral-0 rounded-20 px-10 py-4 sm:w-full max-sm:px-4 max-sm:py-3"
                : theme === "secondary"
                  ? "bg-neutral-0 border-primary-1000 border-2 hover:bg-neutral-0 hover:border-secondary-70 active:bg-neutral-0 active:border-secondary-70 hover:text-secondary-70 active:text-secondary-70 text-primary-1000 rounded-20 whitespace-nowrap px-10 py-4"
                  : theme === "primary-m"
                    ? "bg-secondary-70 hover:bg-secondary-50 hover:border-secondary-50 active:bg-secondary-50 active:border-secondary-50 text-neutral-0 rounded-20 text-mobile-item-m px-4 py-3"
                    : theme === "secondary-m"
                      ? "bg-neutral-0 border-primary-1000 border-2 hover:bg-neutral-0 hover:border-primary-80 active:bg-neutral-0 active:border-primary-80 hover:text-primary-80 active:text-primary-80 text-primary-1000 rounded-20 whitespace-nowrap text-mobile-item-m"
                      : "bg-secondary-70 hover:bg-secondary-50 hover:border-secondary-50 active:bg-secondary-50 active:border-secondary-50 text-neutral-0 rounded-20 px-10 py-4"
          } w-full sm:w-auto transition duration-300 sm:text-item text-mobile-item-m sm:whitespace-nowrap`}
        onClick={onClick}
        actived={actived}
      >{children}
      </button>
    </NavLink>
  );
};

export default ButtonWeb;
