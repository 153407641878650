import { useState } from "react";
import CollapseText from "../../../components/CollapseText";
import { useTranslation } from "react-i18next";

const AboutApp = () => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);

  function onclick(id) {
    collapse.forEach((element, index) => {
      if (index === id) {
        setCollapse((prev) => {
          const newCollapse = [...prev];
          newCollapse[index] = !element;
          return newCollapse;
        });
      } else {
        setCollapse((prev) => {
          const newCollapse = [...prev];
          newCollapse[index] = false;
          return newCollapse;
        });
      }
    }
    );
  }

  return (
    <div className="pt-6 sm:ml-20 sm:pt-12">
      <h2 className="text-mobile-heading-h2 sm:text-heading-h2">{t("faqs.button_app")}</h2>
      <div className="pt-6 grid gap-4 sm:pl-20 sm:pt-8">
        <CollapseText onClick={() => onclick(0)} isOpen={collapse[0]} title={t("faqs.app_title_faq1")} description={t("faqs.app_description_faq1")} />
        <CollapseText onClick={() => onclick(1)} isOpen={collapse[1]} title={t("faqs.app_title_faq2")} description={t("faqs.app_description_faq2")} />
        <CollapseText onClick={() => onclick(2)} isOpen={collapse[2]} title={t("faqs.app_title_faq3")} description={t("faqs.app_description_faq3")} />
        <CollapseText onClick={() => onclick(3)} isOpen={collapse[3]} title={t("faqs.app_title_faq4")} description={t("faqs.app_description_faq4")} />
        <CollapseText onClick={() => onclick(4)} isOpen={collapse[4]} title={t("faqs.app_title_faq5")} description={t("faqs.app_description_faq5")} />
        <CollapseText onClick={() => onclick(5)} isOpen={collapse[5]} title={t("faqs.app_title_faq6")} description={t("faqs.app_description_faq6")} />
        
      </div>
    </div>
  );
}

export default AboutApp;