

const AppFeatureImage = ({ title, description, image }) => {
  return (
    <>
      <article className="grid place-content-between text-center w-full pt-5 pb-5 sm:w-2/3 sm:pt-0 sm:pb-0">
        <img src={image} alt="" />
        <div>

          <h4 className="pt-6 text-mobile-heading-h4 sm:text-heading-h4">{title}</h4>
          <p className="pt-2 text-mobile-body-l-400 sm:text-body-l-400">{description}</p>
        </div>
      </article>
    </>
  )
}

export default AppFeatureImage;