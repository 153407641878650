import { Trans, useTranslation } from "react-i18next";
import ImgDownload from "../../../components/ImgDownload";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="grid place-content-center bg-cover bg-heroMobileBg bg-center-x sm:bg-heroBg bg-primary-80 text-neutral-0 w-full h-[420px] ">
      <div className="grid grid-flow-row place-items-center text-center text-display-700 max-sm:text-mobile-display-700 mt-[100px]">
        <Trans
          components={{
            span: <span />,
            br: <br />,
            p: <p className="px-6"/>,
          }}
         
        >
          {t("hero.title")}
        </Trans>
       
        {/*TODO Change links */}
        <ImgDownload theme={"hero"} />
      </div>
    </div>
  )
}

export default Hero;