import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LandingPage from "./views/LandingPage";
import HomePage from "./views/web/home/HomePage";
import AboutPage from "./views/web/about/AboutPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AppFeaturePage from "./views/web/app_feature/AppFeaturePage";
import ArticlesPage from "./views/web/articles/ArticlesPage";
import FAQPage from "./views/web/faq/FAQPage";
import TermsPage from "./views/web/terms/TermsPage";
import PrivacyPage from "./views/web/privacy/PrivacyPage";
import CookiePage from "./views/web/cookie/CookiePage";
import ArticleDetailsPage from "./views/web/articles/ArticleDetailsPage";
import PrivacyPageMobile from "./views/web/privacy_mobile/PrivacyPageMobile";
import TermsPageMobile from "./views/web/terms_mobile/TermsMobilePage";
import LegalWarningPage from "./views/web/legal warning/LegalWarning";
import SocialPolicyPage from "./views/web/social policy/SocialPolicy";
import DataSafety from "./views/web/data_safety/DataSafety";
import  DataSafetyConfirmation  from "./views/web/data_safety/DataSafetyConfirmation";
import CommunityGuidelinesPage from "./views/web/community_guidelines/CommunityGuidelinesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        path: "",
        element: <HomePage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "about-contact",
        element: <AboutPage />,
      },
      {
        path: "app_feature",
        element: <AppFeaturePage />,
      },
      {
        path: "articles",
        element: <ArticlesPage />,
      },
      {
        path: "article_details_page/:id",
        element: <ArticleDetailsPage />,
      },
      {
        path: "faq",
        element: <FAQPage />,
      },
      {
        path: "terms",
        element: <TermsPage />,
      },
      {
        path: "privacy_policy",
        element: <PrivacyPage />,
      },
      {
        path: "cookie_policy",
        element: <CookiePage />,
      },
      {
        path: "privacy_policy_mobile",
        element: <PrivacyPageMobile />,
      },
      {
        path: "terms_mobile",
        element: <TermsPageMobile />,
      },
      {
        path: "legal_warning",
        element: <LegalWarningPage />,
      },
      {
        path: "social_policy",
        element: <SocialPolicyPage />,
      },
      {
        path: "download",
        element: <HomePage />,
      },
      {
        path: "data-safety",
        element: <DataSafety />,
      },
      {
        path: "data-safety-confirmation",
        element: <DataSafetyConfirmation />,
      },
      {
        path: "community-guidelines",
        element: <CommunityGuidelinesPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
