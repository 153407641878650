import { useTranslation } from "react-i18next";
import GetInTouch from "../../../components/GetInTouch";

const TermsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-12 text-dark pb-24 max-sm:px-6 max-sm:grid-cols-1 max-sm:pb-12 bg-disabled-5">
        <h1 className="text-heading-h1 col-start-2 col-end-11 pt-24 pb-12 max-sm:pt-12 max-sm:text-mobile-heading-h1">{t("terms.title")}</h1>
        <div className="grid gap-6 col-start-3 col-end-11 pl-20 max-sm:pl-0">
          
          <div>
            <h3 className="text-heading-h3 max-sm:text-mobile-heading-h3">{t("terms.section1-title")}</h3>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section1-description-11")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section2-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section2-description-21")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section2-description-22")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section2-description-23")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section3-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section3-description-31")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section3-description-32")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section4-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section4-description-41")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section4-description-42")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section5-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section5-description-51")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section5-description-52")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section5-description-53")}</p>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section5-description-54")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section6-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section6-description-61")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section7-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section7-description-71")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section8-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section8-description-81")}</p>
          </div>

          <div>
            <h4 className="text-heading-h4 max-sm:text-mobile-heading-h4">{t("terms.section9-title")}</h4>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.section9-description-91")}</p>
          </div>

          <div>
            <p className="text-body-m-400 pt-3 max-sm:text-mobile-body-m-400">{t("terms.always_consult")}</p>
            <p className="text-body-m-700 pt-3 max-sm:text-mobile-body-m-700">{t("terms.effective_date")}</p>
          </div>

        </div>
      </div>
      <a id="contact"/>
      <GetInTouch />
    </>
  );
}

export default TermsPage;