

const ButtonArticles = ({ children }) => {
  return (
    <button className="py-2 px-4 border-primary-1000 border-[1px] text-body-m-400 text-dark rounded-full max-sm:text-mobile-body-m-400">
      {children}
    </button>
  )
}

export default ButtonArticles;