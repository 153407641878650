import React from "react";
import { useState, useEffect } from "react";
import FlagEn from "../images/flag-en.svg";
import FlagIt from "../images/flag-it.svg";
import FlagFr from "../images/flag-fr.svg";
import FlagDe from "../images/flag-de.svg";
import FlagEs from "../images/flag-es.svg";
import FlagSv from "../images/flag-sw.svg";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import ButtonWebNav from "./ButtonWebNav";
import { checkLanguage } from "../utils/checkLanguage";
import i18n from "../i18n";





export default function Language() {
  const [flag, setFlag] = useState(FlagEn);

  const CheckLanguage = (language) => {
    

    if (language == "es-ES" || language == "es") {
      setFlag(FlagEs);
    } else if (language == "it-IT" || language == "it") {
      setFlag(FlagIt);
    } else if (language == "fr-FR" || language == "fr") {
      setFlag(FlagFr);
    } else if (language == "de-DE" || language == "de") {
      setFlag(FlagDe);
    }else if (language == "sv-SV" || language == "sv") {
      setFlag(FlagSv);
    } else {
      setFlag(FlagEn);
    }
  }
  
  const changeLanguage = (e, language) => {
    e.preventDefault();
    i18n.changeLanguage(language);
    CheckLanguage(language);
  }
  
  useEffect(() => {
    const language = checkLanguage();
    CheckLanguage(language);
  }, [])

  return (
    <div>
      <Menu placement="bottom-end">
        <MenuHandler >
          <div className="bg-primary-5 hover:bg-primary-20 hover:border-primary-20 active:bg-primary-30 active:border-primary-30 rounded-2xl px-4 py-4 transition duration-300">

            <img src={flag} alt="" width="22" height="22" />
          </div>

        </MenuHandler>
        <MenuList className="bg-primary-10 border-primary-10 shadow-dropdown rounded-2xl px-2 py-4 flex flex-col justify-start w-52 z-50">
          <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e, "en")} children={
              <div className="flex gap-2">

                <img src={FlagEn} alt="" width="22" height="22" />
                <p className="text-item">English</p>

              </div>
            } ></ButtonWebNav>
          </MenuItem>
           <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e,"it")} children={
              <div className="flex gap-2">

                <img src={FlagIt} alt="" width="22" height="22" />
                <p className="text-item">Italiano</p>
              </div>
            } ></ButtonWebNav>
          </MenuItem>
          <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e,"fr")} children={
              <div className="flex gap-2">

                <img src={FlagFr} alt="" width="22" height="22" />
                <p className="text-item">Français</p>
              </div>
            } ></ButtonWebNav>
          </MenuItem>
          <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e,"de")} children={
              <div className="flex gap-2">

                <img src={FlagDe} alt="" width="22" height="22" />
                <p className="text-item">Deutsch</p>
              </div>
            } ></ButtonWebNav>
          </MenuItem> 
          <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e,"es")} children={
              <div className="flex gap-2">

                <img src={FlagEs} alt="" width="22" height="22" />
                <p className="text-item">Español</p>
              </div>
            } ></ButtonWebNav>
          </MenuItem>
          <MenuItem className="">
            <ButtonWebNav onClick={(e) => changeLanguage(e,"sv")} children={
              <div className="flex gap-2">

                <img src={FlagSv} alt="" width="22" height="22" />
                <p className="text-item">Svenska</p>
              </div>
            } ></ButtonWebNav>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}