import React, { useState } from "react";
import { Trans, useSSR, useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ReactComponent as CornerDecoration } from "../../../images/corner-decoration.svg";
import apiFactory from "../../../helpers/apiFactory";

const DataSafetyConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitedd, setIsSubmitedd] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleCancel = () => {
    navigate("/data-safety");
  };

  const sendTokenRequest = (userToken) => {
    const api = apiFactory();
    api.deleteUserAccount(userToken).then((response) => setIsSubmitedd(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendTokenRequest(token);
  };

  return (
    <>
      <div
        className=" px-[24px] py-[48px] md:px-[117px] md:py-[96px] flex justify-center relative"
        style={{ zIndex: 1 }}
      >
        {/* CONTENT */}
        <div className="w-full ">
          <h1 className="text-mobile-heading-h1 md:text-heading-h1 mb-[24px] md:mb-[32px] text-primary-1000">
          {t("data_safety_confirmation.heading")}
          </h1>
          {!isSubmitedd ? (
            <div className=" mb-[24px] md:mb-[32px] ml-[40px] md:ml-[80px]">
              <p className="text-mobile-body-xl-400 md:text-body-xl-400 max-w-[820px] text-primary-1000">
              {t("data_safety_confirmation.title_text")}
              </p>
            </div>
          ) : (
            ""
          )}

          <div className=" md:ml-[80px]  max-w-[820px]">
            {!isSubmitedd ? (
              <div className="py-[48px] px-[24px] md:pt-[48px] md:pb-[40px] flex flex-col justify-center items-center bg-[#FFFF]  border-[1px] rounded-[20px] shadow-dropdown ">
                <h3 className="text-body-xl-700 md:text-heading-h3 text-center text-[#003A3C]">
                {t("data_safety_confirmation.confirmation")}
                </h3>
                <div className="bg-[#FFFF] py-6 sm:px-6 flex flex-col sm:flex-row tablet:flex-row-reverse md:justify-center gap-4 w-full">
                  <button
                    type="button"
                    className="order-2 tablet:order-1 w-full bg-neutral-0 border-2 text-primary-1000 border-primary-1000 rounded-20 active:bg-neutral-0 hover:border-secondary-70 active:border-secondary-70 hover:text-secondary-70 active:text-secondary-70 text-mobile-item-m px-4 py-3 md:order-1"
                    onClick={handleCancel}
                  >
                    {t("data_safety_confirmation.cancel")}
                  </button>
                  <button
                    type="button"
                    className="order-1 tablet:order-2 w-full bg-secondary-70 hover:bg-secondary-50 hover:border-secondary-50 active:bg-secondary-50 active:border-secondary-50 text-neutral-0 rounded-20 text-mobile-item-m px-4 py-3 md:order-2"
                    onClick={handleSubmit}
                  >
                    {t("data_safety_confirmation.delete")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center bg-secondary-10 py-[40px] px-[20px] rounded-[30px]">
                <p className="text-primary-70 text-body-l-700 text-center">
                {t("data_safety_confirmation.account_deleted")}
                </p>
                <p className=" text-primary-70 text-body-l-400 text-center mt-[30px]">
                {t("data_safety_confirmation.thanks")}
                </p>
              </div>
            )}

            <CornerDecoration
              className="absolute md:bottom-[39.51px] right-[177px] hidden md:block"
              style={{ zIndex: -1 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataSafetyConfirmation;
