import react from 'react';
import { useState } from "react";


const TeamImage = ({ name, job, image, className }) => {

  const [hover, setHover] = useState(false);

  const handleHover = (isHover) => {
    setHover(isHover);
  }

  return (
    <div className="relative h-full mb-16 ml-2 -mr-[34rem] flex-shrink-0 sm:-mr-[26rem] sm:mt-24 sm:ml-0" onMouseOver={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
      <div className={`w-1/3 ${hover ? "sm:w-1/2" : "sm:w-1/3"} transition-all duration-300`}>
        <img src={image} className="object-contain" />
      </div>
      <div className={`absolute -bottom-[4.5rem] left-6 w-80 sm:min-w-max rounded-[10px] bg-primary-20 text-dark p-4 sm:pr-12 sm:ml-24 ${hover ? "sm:h-24 sm:bottom-16 left-28" : "sm:h-14 sm:bottom-3"} transition-all duration-300`}>
        <h4 className="text-mobile-heading-h4 sm:text-heading-h4">
          {name}
        </h4>
        <p className={`pt-3 ${hover ? "sm:visible sm:opacity-100" : "sm:opacity-0"} sm:transition-opacity sm:duration-300`}>{job}</p>
      </div>
    </div>
  )
}

export default TeamImage;