import React from "react";
import { useState } from "react";
import useScrollDirection from "../utils/useScrollDirection";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoDesktop } from "../images/logo-koru-your-acne-coach.svg";
import { ReactComponent as LogoMobile } from "../images/logo-footer-your-acne-coach.svg";
import { ReactComponent as Instagram } from "../images/instagram.svg";
import { ReactComponent as Facebook } from "../images/facebook.svg";
import { ReactComponent as Tiktok } from "../images/tiktok.svg";
import { NavLink } from "react-router-dom";
import ButtonWebNav from "./ButtonWebNav";
import Language from "./Language";
import { FacebookLink, InstagramLink, TiktokLink } from "../utils/constants";

const HeaderWeb = () => {
  const { t } = useTranslation();
  const scrollDirection = useScrollDirection();
  const [navActive, setNavActive] = useState(0);


  const checkNavActive = (navClick) => {
    if (navClick == 1) {
      setNavActive(1);
    } else if (navClick == 2) {
      setNavActive(2);
    } else if (navClick == 3) {
      setNavActive(3);
    } else if (navClick == 4) {
      setNavActive(4);
    } else {
      setNavActive(0);
    }
  };

  return (
    <header className={`sticky ${scrollDirection === "down" ? "-top-[11.2rem]" : "top-0"} flex flex-col items-center justify-center  bg-primary-10 transition-all duration-300 z-50 `}>

      <div className="flex justify-center items-center w-full bg-primary-5">
        <NavLink to="/" className="py-4 px-6" onClick={() => checkNavActive(0)} >
          <div className="bg-cover bg-logo_mobile nav_mobile:bg-logo w-[120px] h-[74px] nav_mobile:w-[130px] nav_mobile:h-[147px]"></div>
        </NavLink>
      </div>
      <div className="absolute top-[60px] right-10 invisible nav_mobile:visible ">
        <Language />
      </div>

      <div className="h-[54px] justify-between items-end w-full gap-1 hidden nav_mobile:flex px-10" >
        <ButtonWebNav selected={navActive == 1 ? true : false} onClick={() => checkNavActive(1)} theme="full" children={t("header.app_features")} route="/app_feature"></ButtonWebNav>
        <ButtonWebNav selected={navActive == 2 ? true : false} onClick={() => checkNavActive(2)} theme="full" children={t("header.articles")} route="/articles"></ButtonWebNav>
        <ButtonWebNav selected={navActive == 3 ? true : false} onClick={() => checkNavActive(3)} theme="full" children={t("header.about_us")} route="/about"></ButtonWebNav>
        <ButtonWebNav selected={navActive == 4 ? true : false} onClick={() => checkNavActive(4)} theme="full" children={t("header.faq")} route="/faq"></ButtonWebNav>
        <div className="flex w-full justify-between items-end">
          {/* Add links social */}
          <a href={InstagramLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m`">
            <Instagram className="w-[22px] h-[23px]" />
          </a>

          <a href={FacebookLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl  p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`">
            <Facebook className="w-[22px] h-[23px]" />
          </a>

          <a href={TiktokLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl  p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`">
            <Tiktok className="w-[22px]" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeaderWeb;