import { React, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as IconArrowBack } from '../../../images/icon-arrow-back.svg';
import { ReactComponent as IconTimer } from '../../../images/icon-timer.svg';
import { ReactComponent as IconCalendar } from '../../../images/icon-cal.svg';
import ButtonArticles from "../../../components/ButtonArticles";
import CollapseText from "../../../components/CollapseText";
import ItemArticle from "../../../components/ItemArticle";
import { useParams } from "react-router-dom";
import articles from './articles.json'
import i18n from "../../../i18n";


const ArticleDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState([false, false, false, false, false, false]);
  const [idArticle, setIdArticle] = useState(parseInt(id));

  const onclick = (id) => {
    collapse.forEach((element, index) => {
      if (index === id) {
        setCollapse((prev) => {
          const newCollapse = [...prev];
          newCollapse[index] = !element;
          return newCollapse;
        });
      } else {
        setCollapse((prev) => {
          const newCollapse = [...prev];
          newCollapse[index] = false;
          return newCollapse;
        });
      }
    }
    );
  }


  const handlerChangePage = (isNext) => {
    if (idArticle === 0 && !isNext) {
      return;
    }
    if (idArticle === articles[i18n.language.split("-")[0]].length - 1 && isNext) {
      return;
    }
    if (isNext) {
      setIdArticle(idArticle + 1);
      return;
    }
    if (!isNext) {
      setIdArticle(idArticle - 1);
      return;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [idArticle]);

  return (
    <>
      {/* SECTION HERO */}
      <img src="https://i.ibb.co/ydfj2L8/hero-article.png" alt="" className="w-full object-contain sm:h-[600px] sm:object-cover" />

      {/* SECTION CONTENT */}
      <div className="grid text-dark grid-cols-1 sm:grid-cols-12">
        <div className="bg-neutral-0 rounded-20 block -top-0 pt-12 pb-8 px-6 sm:relative sm:-mt-28 sm:p-8 sm:col-start-2 sm:col-end-12 md:col-end-8 ">
          <h1 className="text-heading-h1 max-sm:text-mobile-heading-h1 text-[36px]">{articles[i18n.language.split("-")[0]][idArticle].title}</h1>
          <div className="top-5 sm:pl-20 sm:pt-4">
            <div className="grid grid-flow-col gap-4 py-6 place-content-start place-items-center">
              <div className="grid grid-flow-col gap-1 place-content-start place-items-center">
                <IconCalendar />
                <p className="text-mobile-body-m-400 sm:text-body-m-400">{articles[i18n.language.split("-")[0]][idArticle].date}</p>
              </div>
              <div className="grid grid-flow-col gap-1 place-content-start place-items-center">
                <IconTimer />
                <p className="text-mobile-body-m-400 sm:text-body-m-400">{articles[i18n.language.split("-")[0]][idArticle].timeToRead}</p>
              </div>
            </div>
            <div className="grid grid-flow-col place-content-start place-items-center gap-2 sm:gap-4">
              {articles[i18n.language.split("-")[0]][id].tags.map((tag, index) => {
                return <ButtonArticles key={index} children={tag} />
              })}
            </div>
          </div>
        </div>
        <section className="col-start-1 px-6 -mt-0 sm:col-start-3 sm:col-end-10">
          <article className='grid grid-flow-row gap-6 text-dark text-mobile-body-m-400 sm:text-body-m-400'>
            <h2 className='text-mobile-heading-h2 sm:text-heading-h2 text-primary-50'>{t("article_detail.overview")}</h2>
            <Trans
              components={{
                p: <p />,
                br: <br />,
                h3: <h3/>,
                h4: <h4 />,
                ul: <ul />,
                li: <li />,
                h2: <h2/>,
                img: <img/>,
                a:<a/>,
                h1:<h1/>
              }}
            >

              {articles[i18n.language.split("-")[0]][idArticle].article}
            </Trans>
          </article>

          {/* SECTION FAQ */}
          {/* <div className='pt-10'>
            <h2 className='text-mobile-heading-h2 sm:text-heading-h2 pb-6'>FAQS</h2>
            <div className='grid gap-4'>
              <CollapseText onClick={() => onclick(0)} isOpen={collapse[0]} title={"Sapien et ligula ullamcorper malesuada proin libero nunc?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu. Nec ullamcorper sit amet risus nullam. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Purus viverra accumsan in nisl nisi scelerisque eu."} />
              <CollapseText onClick={() => onclick(1)} isOpen={collapse[1]} title={"Sapien et ligula ullamcorper?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu. Nec ullamcorper sit amet risus nullam. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Purus viverra accumsan in nisl nisi scelerisque eu."} />
              <CollapseText onClick={() => onclick(2)} isOpen={collapse[2]} title={"Sapien et ligula ullamcorper malesuada proin libero nunc?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu. Nec ullamcorper sit amet risus nullam. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Purus viverra accumsan in nisl nisi scelerisque eu."} />
              <CollapseText onClick={() => onclick(3)} isOpen={collapse[3]} title={"Sapien et ligula ullamcorper malesuada proin libero nunc?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu. Nec ullamcorper sit amet risus nullam. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Purus viverra accumsan in nisl nisi scelerisque eu."} />
              <CollapseText onClick={() => onclick(4)} isOpen={collapse[4]} title={"Sapien et ligula ullamcorper malesuada proin libero nunc consequat. Dignissim enim sit amet venenatis urna. Morbi tristique senectus?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu."} />
              <CollapseText onClick={() => onclick(5)} isOpen={collapse[5]} title={"Morbi tristique senectus?"} description={"Tellus in hac habitasse platea dictumst vestibulum rhoncus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Enim eu turpis egestas pretium aenean pharetra magna. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan. Commodo odio aenean sed adipiscing diam donec adipiscing tristique risus. Semper quis lectus nulla at. Scelerisque eu ultrices vitae auctor eu. Nec ullamcorper sit amet risus nullam. Facilisi nullam vehicula ipsum a arcu cursus vitae congue. Purus viverra accumsan in nisl nisi scelerisque eu."} />
            </div>
          </div> */}

          {/* SECTION BUTTONS PAGES */}
          <div className="grid grid-flow-col place-items-center w-full py-10 text-primary-1000 pb-12 place-content-between sm:place-content-center">
            <button
              onClick={() => handlerChangePage(false)}
              className={`bg-neutral-0 border-primary-1000 hover:bg-primary-10 text-primary-1000 rounded-md w-full p-2 transition duration-300  whitespace-nowrap ${idArticle == 0 ? "opacity-25" : ''}`}
            >
              <div className="grid grid-flow-col place-items-center place-content-start gap-1 pr-5">
                <IconArrowBack className="w-5" />
                <p className="text-mobile-caption-m-400 sm:text-caption-m-400 ">{t("article_detail.button_previous")}</p>
              </div>
            </button>

            <button
              onClick={() => handlerChangePage(true)}
              className={`bg-neutral-0 border-primary-1000 hover:bg-primary-10 text-primary-1000 rounded-md w-full p-2 transition duration-300  whitespace-nowrap ${idArticle == articles[i18n.language.split("-")[0]].length - 1 ? "opacity-25" : ''}`}
            >
              <div className="grid grid-flow-col place-items-center place-content-start gap-1 pl-5">
                <p className="text-mobile-caption-m-400 sm:text-caption-m-400">{t("article_detail.button_next")}</p>
                <IconArrowBack className="rotate-180 w-5" />
              </div>
            </button>
          </div>
        </section>

        {/* SECTION RELATED ARTICLES */}
        {/* <section className="col-start-1 col-end-13 grid pt-24 bg-secondary-10 w-full grid-cols-1 px-6 py-12 sm:grid-cols-12 sm:px-0">
          <article className="col-start-1 col-end-2 sm:col-start-3 sm:col-end-11">
            <h2 className="text-mobile-heading-h2 text-secondary-90 mb-6 sm:mb-24 sm:text-heading-h2">{t("article_detail.related_articles")}</h2>
            <ItemArticle isLeft={true} route={"/articleDetailsPage"} />
            <ItemArticle isLeft={false} route={"/articleDetailsPage"} />
          </article>
        </section> */}
      </div>
    </ >
  )
}

export default ArticleDetailsPage;