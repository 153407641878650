import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "./views/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import PopUpCookie from "./components/PopUpCookie";
import { checkLanguage } from "./utils/checkLanguage";
import { Helmet } from 'react-helmet';

const App = () => {

  const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      console.log(pathname);
      if (pathname == "/about-contact") {
        window.scrollTo(0, 2500);
      } else {
        window.scrollTo(0, 0);
      }
    }, [pathname]);
  };

  const CheckLanguage = () => {
    console.log(checkLanguage());
    i18n.changeLanguage(checkLanguage());
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <meta property="og:title" content="Koru" />
        <meta property="og:url" content="https://www.koruacne.com/" />
        <meta property="og:description" content="Your acne coach" />
        <meta property="twitter:image" content="https://www.koruacne.com/koru.png" />
        <meta property="twitter:image:width" content="300" />
        <meta property="twitter:image:height" content="300" />
        <meta property="twitter:image:type" content="image/png" />
        <meta property="whatsapp:image" content="https://www.koruacne.com/koru.png" />
        <meta property="whatsapp:image:width" content="300" />
        <meta property="whatsapp:image:height" content="300" />
        <meta property="whatsapp:image:type" content="image/png" />
        <meta property="og:image" content="https://www.koruacne.com/koru.png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:site_name" content="Koru" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:locale:alternate" content="en_GB" />
      </Helmet>
      <CheckLanguage />
      <Layout />
      <ScrollToTop />
      <PopUpCookie />
    </I18nextProvider>
  );
};

export default App;
