import { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { Trans, useTranslation } from 'react-i18next';
import ImageSection1 from '../../../images/section1.png';
import ImageSection1Es from '../../../images/section1_es.png';
import ButtonWeb from '../../../components/ButtonWeb';
import IconStep1 from '../../../images/icon-step1.svg';
import IconStep2 from '../../../images/icon-step2.svg';
import IconStep3 from '../../../images/icon-step3.svg';
import Icon1 from '../../../images/section5-1.png';
import Icon2 from '../../../images/section5-2.png';
import Icon3 from '../../../images/section5-3.png';
import Icon4 from '../../../images/section5-4.png';
import Icon5 from '../../../images/section5-5.png';
import Slider from 'react-infinite-logo-slider'
import IconSlider from '../../../components/IconSlider';
import { ReactComponent as Maps } from '../../../images/maps.svg'
import { ReactComponent as MapMobile } from '../../../images/maps-mobile.svg'
import { ReactComponent as IconSquareOrange } from '../../../images/icon-square-orange.svg'
import { ReactComponent as IconSquareBlue } from '../../../images/icon-square-blue.svg'
import { ReactComponent as Section3 } from '../../../images/section3.svg'
import ItemReview from "../../../components/ItemReview";


const Section = () => {
    const { t } = useTranslation();
    const [id, setId] = useState(0);
    const [hiddenAnna, setHiddenAnna] = useState(false);
    const [hiddenInes, setHiddenInes] = useState(true);
    const [hiddenCharlie, setHiddenCharlie] = useState(true);
    const [lang, setLang] = useState(i18n.language.split("-")[0]);


    function changeText(id) {
        if (id === 0) {
            setId(0)
            setHiddenAnna(false)
            setHiddenInes(true)
            setHiddenCharlie(true)
        } else if (id === 1) {
            setId(1)
            setHiddenInes(false)
            setHiddenAnna(true)
            setHiddenCharlie(true)
        } else if (id === 2) {
            setId(2)
            setHiddenCharlie(false)
            setHiddenAnna(true)
            setHiddenInes(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (id === 0) {
                changeText(1)
            }
            else if (id === 1) {
                changeText(2)
            }
            else if (id === 2) {
                changeText(0)
            }
        }, 8000);

        return () => clearTimeout(timer);
    }, [id])

    useEffect(() => {
        setLang(i18n.language.split("-")[0]);
    }, [i18n.language]);

    return (
        <>
            {/*SECTION 1 IMAGE MOBILE AND TEXT */}
            <section className="grid w-full grid-cols-1 px-6 py-12 sm:grid-cols-12 sm:pt-24 sm:pb-12">
                <div className="grid grid-flow-col col-start-auto col-span-full place-items-center place sm:col-start-1 sm:col-span-5 md:col-span-3 md:col-start-3">
                    {
                        lang === "es" ? <img
                            src={ImageSection1Es}
                            alt="phone image"
                            className="mr-0 w-3/4 md:mr-10 md:w-full"
                        /> :
                            <img
                                src={ImageSection1}
                                alt="phone image"
                                className="mr-0 w-3/4 md:mr-10 md:w-full"
                            />
                    }
                </div>
                <article className="text-dark text-mobile-heading-h2 col-span-full pl-0 pt-8 md:pt-0 sm:place-content-center sm:grid sm:col-span-7 md:col-span-5 md:items-center md:pl-16 sm:text-heading-h2">
                    <Trans
                        components={{
                            span: <span />,
                            p: <p />,
                        }}
                    >
                        {t("section1.ultimate")}
                    </Trans>
                    <div className='grid gap-4 pl-10 text-mobile-body-l-400 pr-4 pt-6 sm:text-body-l-400 md:pl-20 '>
                        <Trans
                            components={{
                                span: <span />,
                                p: <p />,
                            }}
                        >
                            {t("section1.discover")}
                        </Trans>
                        <Trans
                            components={{
                                span: <span />,
                                p: <p />,
                            }}
                        >
                            {t("section1.skin")}
                        </Trans>
                        <div className='pt-6 md:pt-8'>
                            <ButtonWeb route={"/about"} >{t("buttons.learn_more")}</ButtonWeb>
                        </div>
                    </div>
                </article>
            </section>
            {/*SECTION 2 TEXT AND VIDEO */}
            <section className="grid grid-cols-1 pb-0 pt-0 w-full sm:px-32 sm:py-10 md:grid-cols-12 md:pt-24 md:pb-24 place-items-center">
                <article className="col-start-auto col-span-full text-mobile-heading-h2 bg-disabled-5 rounded-none text-dark items-center py-10 pr-4 sm:rounded-20 sm:text-heading-h2 md:col-start-2 md:col-span-5">
                    <div className='pl-6 '>
                        <Trans
                            components={{
                                span: <span />,
                                p: <p />,
                            }}
                        >
                            {t("section2.all_you")}
                        </Trans>
                    </div>
                    <div className='grid gap-4 pl-10 text-mobile-body-l-400 pt-6 sm:text-body-l-400 md:pl-20'>
                        <Trans
                            components={{
                                span: <span />,
                                p: <p />,
                            }}
                        >
                            {t("section2.acne")}
                        </Trans>
                    </div>
                </article>
                <div className='aspect-video overflow-hidden col-start-auto col-span-full rounded-none pb-6 sm:mt-10 sm:rounded-20 md:mt-0 md:col-end-12 md:col-span-4 bg-secondary-40'>
                    <img src={require("../../../images/section2.png")} alt="video" className='object-cover' />
                    {/* <video autoplay controls className='object-cover'>
            <source src={Video} type="video/mp4" onPlay={true} />
          </video> */}
                </div>
            </section>
            {/* SECTION 3 COLOR */}
            <div className="grid w-full bg-primary-5 md:grid-cols-12">
                <div className="col-start-3 col-end-11 items-center text-primary-80 pr-4 pt-24 pb-20 px-6 py-12 text-mobile-display-700 sm:text-display-700 md:pt-24 md:py-0 md:pl-20">
                    <div className="text-center">
                        {t("section3.your_skin")}
                    </div>
                    <div className="text-center pt-8 text-mobile-body-xl-400 sm:text-body-xl-400">
                        <p> - Koru </p>
                    </div>
                </div>
                <Section3 className="hidden md:block sm:w-full sm:row-start-2 sm:col-start-8 sm:col-end-13 sm:mb-4 sm:pr-10" />
            </div>
            {/*SECTION 4 TEXT AND ANIMATIONS */}
            <section className="flex flex-col-reverse pt-12 mb-96 px-6 w-full md:grid md:grid-cols-12 md:pt-24 md:mb-24">
                <article className={`text-primary-1000 items-center py-10 rounded-20 col-start-auto col-end-auto pr-0 text-mobile-heading-h2 sm:mx-32 sm:min-h-max sm:text-heading-h2 md:h-96 md:col-start-2 md:col-end-7 md:pr-4 md:mx-0 md:mb-24`}>
                    <div className='pl-0 md:pl-6'>
                        <Trans
                            components={{
                                span: <span />,
                                p: <p />,
                            }}
                        >
                            {t("section4.take")}
                        </Trans>
                    </div>
                    <div className="relative">
                        <ItemReview hidden={hiddenAnna} name={"Anna"} text={t("section1.anna")} stars={4} z={"z-10"} />
                        <ItemReview hidden={hiddenInes} name={"Ines"} text={t("section1.ines")} stars={5} z={"z-20"} />
                        <ItemReview hidden={hiddenCharlie} name={"Charlie"} text={t("section1.charlie")} stars={4} z={"z-30"} />
                    </div>
                </article>
                <div className='grid place-items-center col-start-auto col-end-auto pl-0 md:pl-20 md:col-start-7 md:col-end-12'>
                    <div className='grid grid-flow-col place-content-center w-full pb-4 md:pb-0 md:place-content-between'>
                        <img src={IconStep1} alt="Step 1" className={`pt-[14px] w-40 mr-32 mt-4 sm:w-60 ${id === 0 ? "drop-shadow-reviews" : "drop-shadow-none"}
            transition-drop-shadow  duration-700`} onMouseOver={() => changeText(0)} />
                        <img src={IconStep2} alt="Step 2" className={`w-32 sm:w-44 ${id === 1 ? "drop-shadow-reviews" : "drop-shadow-none"} transition-drop-shadow duration-700`} onMouseOver={() => changeText(1)} />
                    </div>
                    <img src={IconStep3} alt="Step 3" className={`w-32 sm:w-36 ${id === 2 ? "drop-shadow-reviews" : "drop-shadow-none"} transition-drop-shadow  duration-700`} onMouseOver={() => changeText(2)} />
                </div>
            </section>
            {/*SECTION 5 APP FEATURES SLIDER SCROLL INFINITE */}
            <div className=' bg-neutral-5 text-primary-1000 grid grid-flow-row w-full pt-12 pb-6 place-items-start sm:place-items-center sm:place-content-start sm:py-24'>
                <h3 className='text-display-700 max-sm:pl-6 max-sm:text-mobile-display-700'>{t("section5.app_features")}</h3>
                <div className='w-full overflow-hidden pt-12'>
                    <Slider
                        width="320px"
                        duration={40}
                        pauseOnHover={true}
                        blurBorders={true}
                        blurBoderColor={'bg-primary-10'}
                    >
                        <Slider.Slide>
                            <IconSlider icon={Icon1} title={t("section5.unlimited")} description={t("section5.unlimited_description")} />
                        </Slider.Slide>
                        <Slider.Slide>
                            <IconSlider icon={Icon2} title={t("section5.tools")} description={t("section5.tools_description")} />
                        </Slider.Slide>
                        <Slider.Slide>
                            <IconSlider icon={Icon3} title={t("section5.health")} description={t("section5.health_description")} />
                        </Slider.Slide>
                        <Slider.Slide>
                            <IconSlider icon={Icon4} title={t("section5.yourself")} description={t("section5.yourself_description")} />
                        </Slider.Slide>
                        <Slider.Slide>
                            <IconSlider icon={Icon5} title={t("section5.articles")} description={t("section5.articles_description")} />
                        </Slider.Slide>
                    </Slider>
                </div>
                <div className="px-6 w-full pt-8 sm:w-auto sm:px-0">
                    <ButtonWeb route={"/app_feature"} theme="full">{t("buttons.explore_features")}</ButtonWeb>
                </div>
            </div>

            {/* SECTION MAPS */}
            <div className='overflow-hidden text-dark flex flex-col pb-3 sm:pb-0 sm:grid sm:grid-cols-12'>
                <div className='grid grid-flow-row place-content-start place-items-start col-start-2 col-end-4 px-6 sm:my-24 mt-12'>
                    <h3 className='text-mobile-heading-h3 sm:text-heading-h3'>{t("section6.live_countries")}</h3>
                    <div className="flex pb-8 sm:pb-[50px] pl-10 sm:pl-14">
                        <div className="pr-10">
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.spain")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.italy")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange width={20} alt="" />
                                <p className='pl-2'>{t("section6.france")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.uk")}</p>
                            </div>
                        </div>

                        <div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.germany")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.belgium")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.austria")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.switzerland")}</p>
                            </div>
                            <div className='flex pt-4 sm:pt-6 w-36'>
                                <IconSquareOrange alt="" />
                                <p className='pl-2'>{t("section6.sweden")}</p>
                            </div>
                        </div>

                    </div>
                    {/* <h3 className='text-mobile-heading-h3 sm:text-heading-h3'>{t("section6.coming_soon")}</h3>

                    <div className='flex pl-14 pt-4 sm:pt-6 w-36'>
                        <IconSquareBlue alt="" />
                        <p className='pl-2'>{t("section6.sweden")}</p>
                    </div> */}
                </div>
                <div className='col-start-5 col-end-13 hover:duration-300 duration-300 pl-6 hover:scale-100 hover:translate-x-0 sm:hover:scale-[1.2] sm:hover:translate-x-36'>
                    <Maps alt="maps" className="hidden object-contain sm:block" />
                    <MapMobile alt="maps" className="sm:hidden visible block w-full" />
                </div>
            </div>
        </>
    )
}

export default Section;