import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonWeb from "./ButtonWeb";
import Circle14 from "../images/1-4-circle.svg";
import { ReactComponent as IconInfo } from "../images/icon_info.svg";

const GetInTouch = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);


  const handlerSendEmail = () => {
    if (!checkPolicy || !isValiEmail(email) || name === "" || lastName === "" || email === "" || message === "") {
      setErrorVisible(true);
      return;
    } else {
      setErrorVisible(false);
      let messageEmail = `mailto:support@koruacne.com?body=${message} &subject=Name: ${name} ${lastName} Email:${email}`
      window.location = messageEmail;
    }
  }

  const isValiEmail = (val) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  }

  const handlerChangeName = (e) => {
    setName(e);
  }

  const handlerChangeLastName = (e) => {
    setLastName(e);
  }

  const handlerChangeEmail = (e) => {
    setEmail(e);
  }

  const handlerChangeMessage = (e) => {
    setMessage(e);
  }

  return (
    <>
      <div className={`${open ? "hidden" : "block"} grid place-content-center bg-neutral-0 py-24`} onMouseEnter={() => setOpen(!open)}>
        <p className="text-secondary-70 text-mobile-display-700 sm:text-display-700">{t("about.touch")}</p>
      </div>

      {/* SECTION 5 EXPANDED */}
      <div className={`${open ? "flex flex-col animate-fade-down animate-once animate-duration-500 animate-delay-100 animate-lineal md:flex" : "md:hidden hidden"} grid-cols-12 bg-neutral-0  px-6 py-12 md:py-20 md:grid md:px-0`}>
        <div className="col-start-2 col-end-6">
          <h2 className="text-secondary-70 text-mobile-heading-h2 sm:text-heading-h2">
            {t("about.touch")}
          </h2>
          <div className="pt-8 grid grid-flow-row place-content-between h-full pl-10 md:pl-20">
            <p className="text-mobile-body-xl-400 sm:text-body-xl-400">
              {t("about.touch_description")}
            </p>
            <img src={Circle14} alt="" className="object-cover mb-10 -ml-10 pt-10 w-1/2 md:-ml-0 md:pt-0 md:w-auto" />
          </div>
        </div>

        <div className="col-start-7 col-end-11 text-primary-1000">
          <div className="pt-6">
            <label
              className="block pb-4"
              htmlFor="name"
            >
              {t("forms.name")}
            </label>
            <input onChange={(e) => handlerChangeName(e.target.value)} id="name" placeholder="John" className="bg-secondary-10 rounded-20 py-4 px-4 w-full" />
          </div>
          <div className="pt-6">
            <label
              className="block pb-4"
              htmlFor="last name"
            >
              {t("forms.last_name")}
            </label>
            <input
              id="last_name"

              onChange={(e) => handlerChangeLastName(e.target.value)}
              placeholder="Johnson"
              className="bg-secondary-10 rounded-20 py-4 px-4 w-full"
            />
          </div>
          <div className="pt-6">
            <label
              className="block pb-4"
              htmlFor="email"
            >
              {t("forms.email")}
            </label>
            <input onChange={(e) => handlerChangeEmail(e.target.value)} id="email" placeholder="john@gmail.com" className="bg-secondary-10 rounded-20 py-4 px-4 w-full" />
          </div>

          <div className="pt-6">
            <label
              className="block pb-4"
              htmlFor="message"
            >
              {t("forms.message")}
            </label>
            <textarea
              id="message"

              onChange={(e) => handlerChangeMessage(e.target.value)}
              placeholder={t("forms.placeholder_subject")}
              className="bg-secondary-10 rounded-20 py-4 px-4 w-full"
            />
          </div>
          <p className={`text-caption-400 text-error-50 pt-2 ${errorVisible ? "block" : "hidden"}`}>{t("forms.error_form")}</p>
          <div className="flex gap-2 pt-2 place-items-center">
            <input type="checkbox" id="policy" name="policy" value="policy" onChange={(e) => setCheckPolicy(e.target.checked)} className="text-primary-1000" />
            <h3 className="text-disable-90 text-caption-m-400">
              {t("footer.signing")} <a href="/privacy" className="text-primary-50 text-caption-700">{t("footer.privacy_policy").toLowerCase()}.</a>
            </h3>
            <div class="tooltip">
              <IconInfo />
              <span class="tooltiptext">{t("forms.tooltip")}</span>
            </div>
          </div>
          <div className="pt-6">
            <ButtonWeb onClick={() => handlerSendEmail()} theme="full">{t("buttons.send")}</ButtonWeb>
          </div>
        </div>
      </div>
    </>
  )
}

export default GetInTouch;