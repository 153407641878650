import Hero from "./Hero";
import Section from "./Section";
import { useEffect } from "react"
import { AndroidLink, AndroidLink2, AppleLink } from "../../../utils/constants";

const HomePage = () => {
  useEffect(() => {
    if(window.location.pathname == "/") return;
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
      window.location.href = AndroidLink;
  } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
      window.location.href = AppleLink;
  }  else {
      window.location.replace('/');
    }
  }, [])
  return (
    <>
      <Hero />
      <Section />
    </>
  );
}

export default HomePage;