import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-infinite-logo-slider";
import ItemArticle from "../../../components/ItemArticle";
import IconSlider from "../../../components/IconSlider";
import IconExample from "../../../images/icon-example.svg";
import { ReactComponent as IconArrowBack } from "../../../images/icon-arrow-back.svg";
import i18n from "../../../i18n";
import articles from "./articles.json";

const ArticlesPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [lang, setLang] = useState(i18n.language.split("-")[0]);
  const [items, setItems] = useState([]);
  const [length, setLength] = useState(0);
  const articlesPerPage = 6;

  const indexOfLastArticle = page * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = items.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlerChangePage = (isNext) => {
    if (page === 1 && !isNext) {
      return;
    }
    if (isNext) {
      setPage(page + 1);
      return;
    }
    if (!isNext) {
      setPage(page - 1);
      return;
    }
  };

  const getArticles = () => {
    setItems(articles[i18n.language.split("-")[0]]);
    setLength(articles[i18n.language.split("-")[0]].length);
  };

  useEffect(() => {
    setLang(i18n.language.split("-")[0]);
    setItems([]);
    getArticles();
  }, [i18n.language]);

  return (
    <>
      {/* SECTION HERO */}
      <section className="grid grid-cols-1 px-6 py-12 text-dark sm:grid-cols-12 sm:px-0 sm:py-24">
        <article className="py-0 sm:py-10 sm:col-end-12 sm:col-start-2 md:col-end-8 ">
          <h1 className="text-heading-h1 max-sm:text-mobile-heading-h1">
            {t("articles.title")}
          </h1>
          <p className="pt-8 pl-10 text-mobile-body-xl-400 sm:text-body-xl-400 sm:pl-20">
            {t("articles.description")}
          </p>
        </article>
      </section>

      {/* TODO HIDE SECTION WRITERS */}
      {/* <div className={`py-12 bg-neutral-5 text-dark grid grid-flow-row place-items-center w-full max-sm:py-12 max-sm:place-items-center`}>
        <h3 className='text-display-700 max-sm:pl-0 max-sm:text-mobile-display-700'>{t("articles.writers")}</h3>
        <div className={`w-full overflow-hidden pt-10`}>
          <Slider
            width="340px"
            duration={40}
            pauseOnHover={true}
          >
            <Slider.Slide>
              <IconSlider isHover={true} icon={IconExample} title={"Beatriz Rose"} description={t("section5.unlimited_description")} />
            </Slider.Slide>
            <Slider.Slide>
              <IconSlider isHover={true} icon={IconExample} title={"Christian Bale"} description={t("section5.tools_description")} />
            </Slider.Slide>
            <Slider.Slide>
              <IconSlider isHover={true} icon={IconExample} title={"Lorena Perth"} description={t("section5.health_description")} />
            </Slider.Slide>
            <Slider.Slide>
              <IconSlider isHover={true} icon={IconExample} title={"Chris Long"} description={t("section5.yourself_description")} />
            </Slider.Slide>
            <Slider.Slide>
              <IconSlider isHover={true} icon={IconExample} title={"Patrick Sarandon"} description={t("section5.articles_description")} />
            </Slider.Slide>
          </Slider>
        </div>
      </div> */}

      {/* SECTION ARTICLES */}
      {/* TODO AÑADIR pt-24 cuando se añada los escritores*/}
      <section className="text-dark gap-12 pt-12 pb-6 mx-6 block sm:grid sm:grid-cols-12 sm:pb-12 sm:mx-0 sm:pt-0">
        {currentArticles.map((item, index) => {
          return (
            <ItemArticle
              article={item}
              key={index}
              isLeft={index % 2 == 0}
              route={`/article_details_page/${item.id}`}
            />
          );
        })}
      </section>
      

      {/*PAGINATION*/}
      <div
        className={`grid grid-flow-col place-items-center place-content-center w-full text-primary-1000 pb-12 sm:pb-24 ${
          length > 5 ? "block" : "hidden"
        }`}
      >
        <button
          onClick={() => handlerChangePage(false)}
          className={`bg-neutral-0 border-primary-1000 hover:bg-primary-10  hover:text-primary-80 active:text-primary-80 text-primary-1000 rounded-md w-full p-4 transition duration-300  whitespace-nowrap ${
            page === 1 ? "cursor-not-allowed" : ""
          }}`}
        >
          <IconArrowBack className="w-5" />
        </button>

        <p className="px-5 py-3 text-mobile-caption-m-400 sm:text-caption-m-400">
          {page}
        </p>
        <button
          onClick={() => handlerChangePage(true)}
          className={`bg-neutral-0 border-primary-1000 hover:bg-primary-10  hover:text-primary-80 active:text-primary-80 text-primary-1000 rounded-md w-full p-4 transition duration-300  whitespace-nowrap`}
        >
          <IconArrowBack className="rotate-180 w-5" />
        </button>
      </div>
    </>
  );
};

export default ArticlesPage;
