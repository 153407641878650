import { useState, useEffect } from "react";
import ButtonWebNav from "./ButtonWebNav";
import IconMenu from "../images/icon-menu.svg";
import IconClose from "../images/icon-close.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as Instagram } from "../images/instagram.svg";
import { ReactComponent as Facebook } from "../images/facebook.svg";
import { ReactComponent as Tiktok } from "../images/tiktok.svg";
import IconArrowBack from "../images/icon-arrow-back.svg";
import FlagEn from "../images/flag-en.svg";
import FlagIt from "../images/flag-it.svg";
import FlagFr from "../images/flag-fr.svg";
import FlagDe from "../images/flag-de.svg";
import FlagEs from "../images/flag-es.svg";
import FlagSv from "../images/flag-sw.svg";
import { checkLanguage } from "../utils/checkLanguage";
import i18n from "../i18n";
import { FacebookLink, InstagramLink, TiktokLink } from "../utils/constants";



const NavMobile = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [flag, setFlag] = useState(FlagEn);
  const [lang, setLang] = useState("English");

  const CheckLanguage = (language) => {


    if (language == "es-ES" || language == "es") {
      setFlag(FlagEs);
      setLang("Español");
    } else if (language == "it-IT" || language == "it") {
      setFlag(FlagIt);
      setLang("Italiano");
    } else if (language == "fr-FR" || language == "fr") {
      setFlag(FlagFr);
      setLang("Français");
    } else if (language == "de-DE" || language == "de") {
      setFlag(FlagDe);
      setLang("Deutsch");
    } else if (language == "sv-SV" || language == "sv") {
      setFlag(FlagSv);
      setLang("Svenska");
    } 
    else {
      setFlag(FlagEn);
      setLang("English");
    }
  }

  const changeLanguage = (e, language) => {
    e.preventDefault();
    i18n.changeLanguage(language);
    CheckLanguage(language);
    setOpenLanguage(!openLanguage)
  }

  useEffect(() => {
    const language = checkLanguage();
    CheckLanguage(language);
  }, [])

  return (
    <div className="nav_mobile:hidden grid place-items-center">
      <div className={` ${open ? "animate-fade-up animate-once animate-duration-500 animate-delay-100 animate-lineal w-full bg-primary-1000 bg-opacity-20 fixed top-0 bottom-0" : ""}`}></div>
      <div className={` ${open ? "block animate-fade-up animate-once animate-duration-500 animate-delay-100 animate-ease-in-out" : "invisible"} text-primary-1000 fixed bottom-3 bg-transparent w-full z-50`}>
        <div className="flex flex-col gap-4 bg-primary-10 p-[20px] rounded-20 mx-4 h-full">
          <ButtonWebNav children={t("header.app_features")} onClick={() => {
            setOpen(!open)
            if (open) {
              document.body.style.overflow = "scroll";
            } else {
              document.body.style.overflow = "hidden";
            }
          }
          }
            route="/app_feature"
          />
          <ButtonWebNav children={t("header.articles")} onClick={() => {
            setOpen(!open)
            if (open) {
              document.body.style.overflow = "scroll";
            } else {
              document.body.style.overflow = "hidden";
            }
          }}
            route="/articles" />
          <ButtonWebNav children={t("header.about_us")} onClick={() => {
            setOpen(!open)
            if (open) {
              document.body.style.overflow = "scroll";
            } else {
              document.body.style.overflow = "hidden";
            }
          }} route="/about" />
          <ButtonWebNav children={t("header.faq")} onClick={() => {
            setOpen(!open)
            if (open) {
              document.body.style.overflow = "scroll";
            } else {
              document.body.style.overflow = "hidden";
            }
          }} route="/faq" />
          <ButtonWebNav children={
            <div className="flex justify-center gap-2">
              <img src={flag} alt="" width="22" height="22" />
              <p className="text-item">{lang}</p>
            </div>
          } onClick={() => setOpenLanguage(!openLanguage)} />

          <div className="flex justify-center gap-6 pb-20">
          <a href={InstagramLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl  p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m`">
                <Instagram className="w-[22px]" />
              </a>

              <a href={FacebookLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`">
                <Facebook className="w-[22px]" />
              </a>

              <a href={TiktokLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl p-4 transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`">
                <Tiktok className="w-[22px]" />
              </a>
          </div>
        </div>


        {/* LANGUAGE */}
        <div className={` ${openLanguage ? "block animate-fade-left animate-once animate-duration-500 animate-delay-100 animate-ease-in-out" : "invisible"} text-primary-1000 fixed bottom-0 bg-transparent w-full h-full mb-6`}>
          <div className="flex flex-col gap-4 bg-primary-10 p-[20px] rounded-20 mx-4 h-full">
            <div className="grid grid-flow-col grid-cols-4 place-items-center">
              <button
                onClick={() => setOpenLanguage(!openLanguage)}
                className={"col-start-0 col-end-1 mt-2 mb-2 mr-2 bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-[14px] p-3 w-11 h-11 text-item "}
              >
                <img src={IconArrowBack} alt="arrow back" />
              </button>
              <h4 className="col-start-1 col-end-5 text-mobile-heading-h4 -ml-4 ">{t("common.select_language")}</h4>
            </div>

            <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagEn} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">English</p>
              </div>
            } onClick={(e) => changeLanguage(e, "en")} actived={flag == FlagEn ? true : false} theme="ghost" />

             <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagIt} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">Italiano</p>
              </div>
            } onClick={(e) => changeLanguage(e,"it")} actived={flag == FlagIt ? true : false} theme="ghost" /> 

           <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagFr} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">Français</p>
              </div>
            } onClick={(e) => changeLanguage(e,"fr")} actived={flag == FlagFr ? true : false} theme="ghost" />

            <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagDe} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">Deutsch</p>
              </div>
            } onClick={(e) => changeLanguage(e,"de")} actived={flag == FlagDe ? true : false} theme="ghost" /> 
            <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagEs} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">Español</p>
              </div>
            } onClick={(e) => changeLanguage(e, "es")} actived={flag == FlagEs ? true : false} theme="ghost" />
             <ButtonWebNav children={
              <div className="flex justify-center gap-2">
                <img src={FlagSv} alt="" width="22" height="22" />
                <p className="text-mobile-item-m">Svenska</p>
              </div>
            } onClick={(e) => changeLanguage(e, "sv")} actived={flag == FlagSv ? true : false} theme="ghost" />
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          setOpen(!open)
          setOpenLanguage(false)
          if (open) {
            document.body.style.overflow = "scroll";
          } else {
            document.body.style.overflow = "hidden";
          }
        }
        }
        className={"bg-primary-50 hover:bg-primary-40 hover:border-primary-40 active:bg-primary-40 active:border-primary-40 text-neutral-0 rounded-20 py-4 px-6 text-item z-50 fixed-position-on-mobile"}
      >
        <div className="grid grid-flow-col">
          <img src={` ${open ? IconClose : IconMenu}`} alt="" className="w-6" />
          <p className="text-item pl-4">Menu</p>
        </div>
      </button>

    </div>
  );
}

export default NavMobile;