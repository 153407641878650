import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ButtonWebNav from "./ButtonWebNav";
// import { ReactComponent as LogoFooter } from "../images/logo.svg";
import { ReactComponent as LogoFooter } from "../images/logo-footer-your-acne-coach.svg";
import { ReactComponent as Copy } from "../images/copyright.svg";
import { ReactComponent as Instagram } from "../images/instagram.svg";
import { ReactComponent as Facebook } from "../images/facebook.svg";
import { ReactComponent as Tiktok } from "../images/tiktok.svg";
import { ReactComponent as IconClose } from "../images/icon-close-circle.svg";
import { ReactComponent as IconMail } from "../images/icon-email-fav.svg";
import { ReactComponent as IconInfo } from "../images/icon_info.svg";
import { useTranslation } from "react-i18next";
import { FacebookLink, InstagramLink, TiktokLink } from "../utils/constants";
import ImgDownload from "./ImgDownload";
import db from "../firebase";
import { ref, set } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';



const FooterWeb = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);

  const saveEmail = (text) => {
    set(ref(db, 'emails/' + uuidv4()), {
      email: text,
    }).then(() => {
      setIsVisible(true);
      setEmail("");
    })
      .catch((error) => {
        setIsVisible(false);
      });
  };


  function isValiEmail(val) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  }

  const handleTypeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (isValiEmail(e.target.value)) {
      setErrorVisible(false);
    } else {
      setErrorVisible(true);
    }
  };

  const handleSetVisible = (e) => {
    e.preventDefault();
    if (isValiEmail(email)) {
      if (checkPolicy) {
        setIsVisible(true);
        saveEmail(email);
        setErrorVisible(false);
      } else {
        setErrorVisible(true);
      }
    } else {
      setIsVisible(false);
      setErrorVisible(true);
    }
  };

  return (
    <footer className="inline-block px-6 pb-24 bg-primary-10 py-10 text-primary-1000 md:grid md:grid-cols-12 md:place-content-center ">
      {/* Logo section */}
      <div className=" md:grid-flow-row md:place-content-between md:col-start-2 md:col-end-4 h-full">
        <NavLink to="/">
          <LogoFooter className="md:w-[200px] md:h-[122px] "/>
        </NavLink>
        <div className="flex items-center md:grid md:grid-flow-col  md:mb-4 md:mt-[28px] mt-[24px] md:justify-start">
          <Copy />{" "}
          <span className="ml-2 text-primary-1000 text-mobile-caption-m-400 md:text-caption-m ">
            {t("footer.all_rights")}
          </span>
        </div>
      </div>
      <div className="pt-10 md:pt-0 md:col-start-5 md:col-end-12">
        <div className="block sm:flex sm:flex-wrap md:grid md:grid-flow-row">
          {/* Download section */}
          <div className="block sm:flex sm:flex-wrap md:grid md:grid-flow-col md:grid-cols-12 md:place-content-between">
            <div className="min-w-min md:col-start-0 md:col-span-5 sm:mr-16 sm:mb-10">
              <h4 className="text-mobile-heading-h4 md:text-heading-h4">
                {t("footer.download")}
              </h4>
              <p className="text-disable-90 text-body-m-400 pt-2 max-sm:text-mobile-body-m-400">
                {t("footer.download_description")}
              </p>
              <ImgDownload theme={"footer"} />
            </div>
            {/* Sign up section */}
            <div className="pt-10 pl-0 text-mobile-caption-m-400 sm:pt-0 sm:col-span-7 md:text-caption-m-400 max-w-lg">
              <h4 className="text-mobile-heading-h4 md:text-heading-h4">
                {t("footer.sign_up")}
              </h4>
              <form className="pt-4">
                <div class="relative ">
                  <input type="email" id="email" value={email} onChange={(e) => handleTypeEmail(e)} placeholder={t("footer.email")} className="block w-full border-none pl-4 rounded-2xl text-dark  px-6 py-3 text-mobile-body-m-400 mb-2 sm:mb-0 md:text-body-m-400" required />
                  <button onClick={(e) => handleSetVisible(e)} className="bg-secondary-70 hover:bg-secondary-50 text-mobile-item text-neutral-0  -right-0.5 -bottom-0.5 px-6 py-3 rounded-2xl static w-full sm:w-auto md:text-item sm:absolute">{t("buttons.sign_up")}</button>
                </div>
              </form>
              <p className={`text-caption-400 text-error-50 pt-2 ${errorVisible ? "block" : "hidden"}`}>{t("footer.error_email")}</p>
              <div className="flex gap-2 pt-2 place-items-center">
                <input type="checkbox" id="policy" name="policy" value="policy" onChange={(e) => setCheckPolicy(e.target.checked)} className="text-primary-1000" />
                <h3 className="text-disable-90 text-caption-m-400">
                  {t("footer.signing")} <a href="/privacy" className="text-primary-50 text-caption-700">{t("footer.privacy_policy").toLowerCase()}.</a>
                </h3>
                <div class="tooltip">
                  <IconInfo />
                  <span class="tooltiptext">{t("footer.tooltip")}</span>
                </div>
              </div>
            </div>
          </div>
          {/* Nav section */}
          <div className="">
            <div className="pt-10 block sm:flex sm:flex-wrap gap-1">
              <ButtonWebNav route="/faq" align={"max-sm:text-left"}>
                {t("footer.faq")}
              </ButtonWebNav>
              <ButtonWebNav route="/about-contact" align={"max-sm:text-left"}>
                {t("footer.contact")}
              </ButtonWebNav>
              <ButtonWebNav route="/about" align={"max-sm:text-left"}>
                {t("footer.about_us")}
              </ButtonWebNav>

              <div className="pl-0 sm:pl-10 flex place-items-center gap-1">

                <a href={InstagramLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 p-3 rounded-2xl transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m`">
                  <Instagram className="w-[24px]" />
                </a>

                <a href={FacebookLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl transition duration-300 p-3 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`" >
                  <Facebook className="w-[22px]" />
                </a>

                <a href={TiktokLink} target="_blank" className="bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 p-3 rounded-2xl transition duration-300 font-semibold sm:text-item sm:whitespace-nowrap text-mobile-item-m max-sm:text-left`">
                  <Tiktok className="w-[24px]" />
                </a>
              </div>
            </div>
            <div className=" block sm:flex sm:flex-wrap gap-1">
              <ButtonWebNav route="/terms" align={"max-sm:text-left"} theme="footer">
                {t("footer.terms_conditions")}
              </ButtonWebNav>
              <ButtonWebNav route="/privacy_policy" align={"max-sm:text-left"} theme="footer">
                {t("footer.privacy_policy")}
              </ButtonWebNav>
              <ButtonWebNav route="/cookie_policy" align={"max-sm:text-left"} theme="footer">
                {t("footer.cookie_policy")}
              </ButtonWebNav>
              <ButtonWebNav route="/social_policy" align={"max-sm:text-left"} theme="footer">
                {t("footer.social_policy")}
              </ButtonWebNav>
              <ButtonWebNav route="/legal_warning" align={"max-sm:text-left"} theme="footer">
                {t("footer.legal")}
              </ButtonWebNav>
              <ButtonWebNav route="/data-safety" align={"max-sm:text-left"} theme="footer">
                {t("footer.data-safety")}
              </ButtonWebNav>
              <ButtonWebNav route="/community-guidelines" align={"max-sm:text-left"} theme="footer">
                {t("footer.community_guidelines")}
              </ButtonWebNav>

            </div>
          </div>
        </div>
        {/* POPUP NEWSLETTER */}
        <div className={`${isVisible ? "block" : "hidden"}`}>
          <div className={`fixed text-primary-1000 z-50 grid bg-neutral-0 px-4 pt-10 pb-24 shadow-dropdown animate-fade-up duration-100 bottom-0 right-0 left-0 rounded-t-20 sm:min-w-max sm:bottom-8 sm:right-8 sm:left-auto sm:animate-fade-left sm:rounded-20 sm:px-6 sm:py-12 sm:pr-20`}>
            <div className="grid place-content-center">
              <div className="grid grid-flow-col place-content-start place-items-center sm:pl-6">
                <IconMail className="" />
                <h2 className="text-mobile-body-xl-700 sm:text-body-xl-700 pl-3 sm:pl-4">{t("newsletter.title")}</h2>
              </div>
            </div>
            <button onClick={
              () => {
                setIsVisible(!isVisible)
              }
            }
              className="absolute top-2 right-2"><IconClose /></button>
          </div>
        </div>
      </div>
    </footer >
  );
};

export default FooterWeb;