import { useState } from 'react'

const IconSlider = ({ icon, title, description, isHover = false }) => {
  const [open, setOpen] = useState(false);

  function handlerOpen(isOpen) {
    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    console.log(isOpen);
  }

  if (isHover) {
    return (
      <div className={`grid place-content-end px-4 text-dark`} onMouseOver={() => handlerOpen(true)} onMouseLeave={() => handlerOpen(false)} onClick={() => handlerOpen(!open)}>
        <div className='grid grid-flow-col place-items-center place-content-start' >
          <img src={icon} alt="any" className='w-20' />
          <p className='pl-6 text-heading-h5'>{title}</p>
        </div>
        <div className={`${open ? "animate-fade-down duration-75 visible" : "invisible"}`}>
          <p className={` pt-4 pl-10 text-mobile-body-m-400 sm:text-body-m-400`}>{description}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='px-4 text-dark'>
        <div className='grid grid-flow-col place-items-center place-content-start'>
          <img src={icon} alt="any" className='w-20' />
          <p className='pl-6 text-mobile-heading-h5 sm:text-heading-h5'>{title}</p>
        </div>
        <p className='pt-4 pl-10 text-body-m-400'>{description}</p>
      </div>
    )
  }
}

export default IconSlider