import { Trans, useTranslation } from 'react-i18next';
import Star from '../images/icon-star.svg';

const ItemReview = ({ name, text, stars, hidden, z }) => {
  const { t } = useTranslation();
    return (
      <div className={`grid mt-8 bg-primary-20 rounded-20 p-6 text-mobile-body-l-400 sm:text-body-l-400 md:ml-20 ${hidden ? "invisible transition-all opacity-0 duration-700" : "visible transition-all opacity-100 duration-700"} top-0 absolute ${z}`}>
        <div className='grid grid-flow-col place-content-start gap-1 '>
          {
            [...Array(stars)].map((e, i) => (
              <img key={i} src={Star} alt="star" />
            ))
          }
        </div>
        <div className='pt-4 text-mobile-heading-h5 sm:text-heading-h5'>
        {name}
        </div>
        <div className='pt-2'>
          <Trans
            components={{
              span: <span />,
              p: <p />,
            }}
          > <p>{text}</p>
          </Trans>
        </div>
      </div>

    )}; 

export default ItemReview;