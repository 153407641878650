import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Circle14Faq from "../../../images/1-4-circle-faq.svg";
import ButtonWeb from "../../../components/ButtonWeb";
import GetInTouch from "../../../components/GetInTouch";
import AboutApp from "./AboutApp";
import AboutKoru from "./AboutKoru";
import AboutAcne from "./AboutAcne";

const FAQPage = () => {
  const { t } = useTranslation();
  const [selectPage, setSelectPage] = useState("app");

  return (
    <>
      <div className="grid text-dark grid-cols-1 px-6 py-12 sm:grid-cols-12 sm:px-0 sm:py-24">
        <div className="sm:col-start-2 sm:col-end-12 md:col-end-11">
          <h1 className="text-mobile-heading-h1 sm:text-heading-h1">{t("faqs.title")}</h1>
          <div className="block text-mobile-body-xl-700 pt-4 pl-10 sm:pl-20 sm:pt-8 sm:grid sm:grid-cols-8 sm:text-body-xl-700">
            <div className="sm:col-start-1 sm:col-end-12 md:col-end-6 grid grid-flow-row">
              <div className="">
                <Trans
                  components={{
                    span: <span />,
                    br: <br />,
                    p: <p />,
                    a: <a />,
                  }}
                >
                  {t("faqs.description")}
                </Trans>
              </div>
              <div className="grid grid-flow-col gap-4 place-content-start pt-6 overflow-x-scroll overflow-hidden -mr-6 -ml-16 pl-16 pr-6 scrollbar-hide sm:-mr-0 sm:-ml-0 sm:pl-0 sm:pr-0 sm:overflow-visible sm:overflow-x-visible">
                <div className="w-auto"><ButtonWeb actived={selectPage === "app" ? true : false} onClick={() => setSelectPage("app")} theme="secondary">{t("faqs.button_app")}</ButtonWeb></div>
                <div className="w-auto"><ButtonWeb actived={selectPage === "koru" ? true : false} onClick={() => setSelectPage("koru")} theme="secondary">{t("faqs.button_koru")}</ButtonWeb></div>
                <div className="w-auto"> <ButtonWeb actived={selectPage === "acne" ? true : false} onClick={() => setSelectPage("acne")} theme="secondary">{t("faqs.button_acne")}</ButtonWeb></div>
              </div>
            </div>
            <div className="hidden sm:flex sm:items-end sm:justify-end sm:col-start-7 sm:col-end-9">
              <img src={Circle14Faq} alt="" className="sm:w-32 md:w-52" />
            </div>
          </div>
          <div className={`${selectPage === "app" ? "block animate-fade duration-150" : "hidden"} text-primary-1000`}>
            <AboutApp />
          </div>
          <div className={`${selectPage === "koru" ? "block animate-fade duration-150" : "hidden"} text-primary-1000`}>
            <AboutKoru />
          </div>
          <div className={`${selectPage === "acne" ? "block animate-fade duration-150" : "hidden"} text-primary-1000`}>
            <AboutAcne />
          </div>
        </div>
      </div>
      <a id="contact" />
      <GetInTouch />
    </>
  );
}

export default FAQPage;