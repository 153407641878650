
import { ReactComponent as IconTimer } from '../images/icon-timer.svg';
import { ReactComponent as IconCalendar } from '../images/icon-cal.svg';
import ButtonArticles from "./ButtonArticles";
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';

const ItemArticle = ({ isLeft, article, route }) => {
  return (
    <NavLink
      to={route}
      className="text-dark relative place-items-center mb-6 flex flex-col-reverse sm:grid sm:grid-cols-8 sm:mb-24 sm:col-start-2 sm:col-end-12 md:col-start-3 md:col-end-11">
      <article className={` ${isLeft ? "col-start-1 col-end-6" : "col-start-4 col-span-8"} z-0 w-full sm:z-10`}>
        <div className="rounded-20 bg-disabled-5 hover:bg-primary-10 pr-4 transition-all duration-300 pt-8 pb-4 sm:py-8">
          <h4 className="pl-6 text-mobile-heading-h4 sm:text-heading-h4 ">
            {article.title}
          </h4>
          <div className="pl-10 text-mobile-body-m-400 sm:text-body-m-400 sm:pl-20">
            <h5 className="text-primary-50 py-4 text-mobile-heading-h5 sm:text-heading-h5">{article.author}</h5>
            <p className="text-ellipsis line-clamp-3">
              <Trans
                components={
                  {
                    p: <p />,
                    h3:<h3 />,
                    br: <br />
                  }
                }
              >
                {article.article}
              </Trans>
            </p>
            <div className="grid grid-flow-col gap-4 py-6 place-content-start place-items-center whitespace-nowrap">
              <div className="grid grid-flow-col gap-1 place-content-start place-items-center">
                <IconCalendar />
                <p className="text-mobile-body-m-400 sm:text-body-m-400">{article.date}</p>
              </div>
              <div className="grid grid-flow-col gap-1 place-content-start place-items-center">
                <IconTimer />
                <p className="text-mobile-body-m-400 sm:text-body-m-400">{article.timeToRead}</p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap place-content-start place-items-center gap-2 sm:gap-4">
              {
                article.tags.map((tag, index) => {
                  return <ButtonArticles key={index} children={tag} />
                })
              }
            </div>
          </div>
        </div>
      </article>
      <div className={`${isLeft ? "sm:absolute end-0 rounded-tr-[300px] rounded-br-20 rounded-tl-20 rounded-bl-20 " : "sm:absolute start-0 rounded-tl-[300px] rounded-br-20 rounded-tr-20 rounded-bl-20"} static bg-secondary-20 mb-6 h-56 w-full sm:mb-0 sm:h-96 sm:w-2/3`}>
      </div>
    </NavLink>
  )

};


export default ItemArticle;