import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonWebNav from "./ButtonWebNav";
import ButtonWeb from "./ButtonWeb";
import { ReactComponent as IconClose } from "../images/icon-close-circle.svg";


const PopUpCookie = () => {
  const { t } = useTranslation();
  const [close, setClose] = useState(false);

  const handlerClose = (e) => {
    e.preventDefault();
    setClose(!close);
  }

  return (
    <div className={`fixed duration-100 text-primary-1000 text-body-m-700 max-sm:text-mobile-body-m-700 rounded-20 grid bg-neutral-0 pb-10 shadow-dropdown bg-no-repeat bg-center-x bg-contain z-50 animate-fade-up bg-cookie_mobile bg-bottom bottom-0 px-6 pt-16 sm:animate-fade-left sm:max-w-min sm:bottom-8 sm:right-8 sm:px-6 sm:pt-12 sm:bg-cookie sm:bg-left sm:bg-cover rounded-b-none sm:rounded-b-20 ${close ? "hidden" : ""}`}>
      <div className="pl-0 pr-0 pb-16 sm:pl-24 sm:pr-4 sm:pb-0">
        <h3 className="pb-2 text-mobile-heading-h3 sm:text-heading-h3">{t("cookie.title_popup")}</h3>
        <div className=" sm:pl-20 ">
          <Trans
            components={{
              span: <span />,
              a: <a />,
              p: <p />,
            }}
          >
            {t("cookie.description_popup")}
          </Trans>
          <div className="grid pt-6 grid-flow-row gap-4 sm:grid-flow-col sm:gap-6">
            <ButtonWeb theme="primary-m" onClick={(e) => handlerClose(e) }
              children={t("cookie.button_accept")} />
            <ButtonWebNav route="/cookie_policy" theme="secondary-m" children={t("cookie.button_necessary")} />
          </div>
        </div>
      </div>
      <button onClick={() => {
        setClose(!close)
      }
      }
        className="absolute top-2 right-2"><IconClose /></button>
    </div>
  );
};

export default PopUpCookie;