export const checkLanguage = () => {
  const language = navigator.language || navigator.userLanguage;

  switch (true) {
    case language.includes("en"):
      return "en-GB";
    case language.includes("es"):
      return "es-ES";
    case language.includes("fr"):
      return "fr-FR";
    case language.includes("de"):
      return "de-DE";
    case language.includes("it"):
      return "it-IT";
    case language.includes("sv"):
      return "sv-SV";

    default:
      return "en-GB";
  }
};
