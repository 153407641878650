import { Collapse } from "@material-tailwind/react";
import IconArrowDown from "../images/icon-arrow-down.svg";

const CollapseText = ({ title, description, isOpen, onClick }) => {
  return (
    <div onClick={onClick} className="rounded-20 w-full bg-disabled-5 p-6 overflow-hidden cursor-pointer">
      <div className="grid grid-flow-col place-content-between place-items-center">
        <h4 className="text-mobile-heading-h4 sm:text-heading-h4">{title}</h4>
        <img src={IconArrowDown} alt="" className={`${isOpen ? "rotate-180 transition-all duration-150" : "transition-all duration-150"}  `} />
      </div>
      <Collapse open={isOpen} children={<div className="text-mobile-body-m-400 pt-6 sm:text-body-m-400">{description}</div>} />
    </div>
  );
};


export default CollapseText;