import { Trans, useTranslation } from "react-i18next";
import GetInTouch from "../../../components/GetInTouch";

const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <>
    <div className="grid grid-cols-12 text-dark pb-24 max-sm:px-6 max-sm:grid-cols-1 max-sm:pb-12 bg-disabled-5">
      <h1 className="text-heading-h1 col-start-2 col-end-11 pt-24 pb-12 max-sm:pt-12 max-sm:text-mobile-heading-h1">{t("privacy.title")}</h1>
      <div className="grid gap-6 col-start-3 col-end-11 pl-20 max-sm:pl-0">
        <div>
        <Trans
            components={{
              p: <p />,
              br: <br />,
              h3: <h4 />,
              ul: <ul />,
              li: <li />,
              a:<a />,
            }}
          >

            {t("privacy.web")}
          </Trans>
        </div>
      </div>
    </div>
    <a id="contact"/>
    <GetInTouch />
  </>
  );
}

export default PrivacyPage;