import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonWeb from "../../../components/ButtonWeb";
import { ReactComponent as CornerDecoration } from "../../../images/corner-decoration.svg";
import apiFactory from "../../../helpers/apiFactory";

const DataSafety = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [dataView, setDataView] = useState("app");
  const [errors, setErrors] = useState({});
  const [isSubmitedd, setIsSubmitedd] = useState(false);

  const handleChangeViewData = (area) => {
    setDataView(area);
  };

  const isButtonDisabled = email.trim() === "";

  const isValidEmail = (value) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const sendEmailRequest = (email) => {
    const api = apiFactory();
    api
      .requestDeleteUserAccount(email)
      .then((response) => setIsSubmitedd(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
    } else if (email.trim() === "") {
      errors.email = "Email is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully!");

      setEmail("");
    }

    sendEmailRequest(email);
  };

  const renderForm = () => (
    <div
      className={`mt-[48px] tablet:mt-[32px] px-[20px] py-[40px] rounded-[10px] ${
        !isSubmitedd
          ? "bg-disabled-5 md:px-[32px]"
          : "bg-secondary-10 rounded-[30px]"
      }`}
    >
      {!isSubmitedd ? (
        <form onSubmit={handleSubmit}>
          <div className="text-mobile-body-l-400 md:text-body-l-400">
            <label className="block pb-4" htmlFor="email">
              {t("data_safety.email")}
            </label>
            <input
              required
              name="email"
              value={email}
              onChange={handleChangeEmail}
              id="email"
              placeholder="john@gmail.com"
              className="bg-secondary-10 rounded-20 py-4 px-4 w-full"
            />
          </div>
          {errors.email && <p className="text-red-500">{errors.email}</p>}

          <div className="mt-[16px] md:mt-[24px]">
            <button
              disabled={isButtonDisabled}
              className={`${
                isButtonDisabled
                  ? "bg-disabled-10 text-disabled-70"
                  : "bg-secondary-70 text-neutral"
              } px-[56px] py-3 w-full rounded-[20px] text-[18px] font-bold`}
            >
              
              {t("data_safety.save")}
            </button>
          </div>
        </form>
      ) : (
        <div className="flex flex-col justify-center items-center bg-secondary-10">
          <p className="text-primary-70 text-body-l-700 text-center">
            {t("data_safety.submitted")}
          </p>
          <p className="text-primary-70 text-body-l-400 text-center">
            {t("data_safety.check")}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="px-[24px] py-[48px] md:px-[117px] md:py-[96px] flex justify-center relative z-9">
        {/* CONTENT */}
        <div className="w-full">
          <h1 className="text-mobile-heading-h1 md:text-heading-h1 mb-[24px] md:mb-[32px] text-primary-1000">
          {t("data_safety.heading")}
          </h1>

          <div className="mb-[24px] md:mb-[32px] ml-[40px] md:ml-[80px]">
            <p className="text-mobile-body-xl-400 md:text-body-xl-400 max-w-[820px] text-primary-1000">
            {t("data_safety.title_text")}
            </p>
          </div>

          {/* SWITCHER */}
          <div className="w-full flex ml-[40px] md:ml-[80px] gap-[16px] mb-[24px] md:mb-[32px] overflow-x-scroll relative z-10">
            <ButtonWeb
              actived={dataView === "app" ? true : false}
              onClick={() => handleChangeViewData("app")}
              theme="secondary"
            >
              {t("data_safety.on_app")}
            </ButtonWeb>

            <ButtonWeb
              actived={dataView === "webpage" ? true : false}
              onClick={() => handleChangeViewData("webpage")}
              theme="secondary"
            >
              {t("data_safety.on_web")}
            </ButtonWeb>
          </div>

          {dataView === "app" ? (
            <div className="ml-[40px] md:ml-[80px] max-w-[820px]">
              <p className="text-body-m-400 md:text-body-l-400 text-primary-1000">
              {t("data_safety.app_request_title")}
              </p>
              <ul className="list-decimal text-body-m-400 md:text-body-l-400 w-full ml-[2px] text-primary-1000">
                <li>
                {t("data_safety.app_request_list1")}
                  <span className="inline-block align-middle">
                    <img
                      src="https://i.ibb.co/5M5Y9wm/avatar-icon.png"
                      className="w-[20px] h-[20px]"
                    />
                  </span>
                  {t("data_safety.app_request_list1-1")}
                </li>
                <li>{t("data_safety.app_request_list2")}</li>
                <li>{t("data_safety.app_request_list3")}</li>
                <li>{t("data_safety.app_request_list4")}</li>
              </ul>
              <p className="text-body-m-400 md:text-body-l-400 text-primary-1000">
              {t("data_safety.app_request_text")}
              </p>
              <CornerDecoration className="absolute md:bottom-[39.51px] right-[177px] hidden md:block z-[-1]" />
            </div>
          ) : (
            <div className=" max-w-[820px]">
              <p className="ml-[40px] md:ml-[80px] text-body-m-400 md:text-body-l-400 text-primary-1000">
              {t("data_safety.web_request_title")}
              </p>
              <ul className="ml-[40px] md:ml-[80px]  list-decimal text-body-m-400 md:text-body-l-400 w-full text-primary-1000">
                <li>
                {t("data_safety.web_request_list1")}
                </li>
                <li>{t("data_safety.web_request_list2")}</li>
                <li>{t("data_safety.web_request_list3")}</li>
              </ul>
              <p className="ml-[40px] md:ml-[80px]  text-body-m-400 md:text-body-l-400 text-primary-1000">
              {t("data_safety.web_request_text")}
              </p>

              <div className="tablet:ml-[40px] md:ml-[80px]">
                {renderForm()}
              </div>
              <CornerDecoration className="absolute md:bottom-[39.51px] right-[177px] hidden md:block z-[-1]" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataSafety;
