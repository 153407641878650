import i18n from "../i18n";
import { useEffect, useState } from "react";
import { AndroidLink, AppleLink } from "../utils/constants";


const ImgDownload = ({ theme }) => {
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  useEffect(() => {
    setLang(i18n.language.split("-")[0]);
  }, [i18n.language]);

  return (
    <div className={`
    ${theme == "footer" ? "flex mt-8 gap-6" : ""} 
    ${theme == "feature" ? "grid place-items-center place-content-center pt-8 grid-flow-row gap-6 sm:grid-flow-col" : ""}
    ${theme == "popupfeature" ? "grid grid-flow-col mt-8 gap-10" : ""}
    ${theme == "hero" ? "flex max-sm:flex-col mt-8 max-sm:mt-10" : ""}`}>
      {/* TODO Añadir link */}
      <a href={AndroidLink} rel="noopener" target="no_blank" >
        {
          lang == "es" ?
          <img src={require("../images/google-play-es.png")} alt="google_play" className={`
          ${theme == "footer" ? "w-36" : ""}
          ${theme == "hero" ? "mr-10 max-sm:mr-0 max-sm:pb-6" : ""}`} />
          :
          <img src={require("../images/google-play.png")} alt="google_play" className={`
          ${theme == "footer" ? "w-36" : ""}
          ${theme == "hero" ? "mr-10 max-sm:mr-0 max-sm:pb-6" : ""}`} />
        }
      </a>
      <a href={AppleLink} rel="noopener" target="no_blank">
        {
          lang == "es" ?
          <img src={require("../images/app-store-es.png")} alt="app_store" className={`
          ${theme == "footer" ? "w-36" : ""}
          `} />
          :
          <img src={require("../images/app-store.png")} alt="app_store" className={`
          ${theme == "footer" ? "w-36" : ""}
          `} />
        }
      </a>
    </div>
  );
};


export default ImgDownload;