import { NavLink } from "react-router-dom";

const ButtonWebNav = ({ children, onClick, theme = "default", actived, route, align, selected, link }) => {
  return (
    <NavLink
      to={route}
      className={`${theme === "full" ? "w-full" : ""}`}
    ><button
      href="https://www.google.es"
      className={`${actived
        ? "bg-primary-30 hover:bg-primary-30 hover:border-primary-30 border-primary-30 hover:text-primary-80 text-primary-80 rounded-20 whitespace-nowrap"
        : selected ? "text-primary-80"
          : theme === "white"
            ? "bg-white text-secondary-60 border border-secondary-60 hover:text-secondary-70 hover:border-secondary-70 active:text-secondary-80 active:border-secondary-80"
            : theme === "secondary"
              ? "bg-neutral-0 border-primary-1000 border-2 hover:bg-neutral-0 hover:border-primary-80 active:bg-neutral-0 active:border-primary-80 hover:text-primary-80 active:text-primary-80 text-primary-1000 rounded-20 whitespace-nowrap"
              : theme === "primary-m"
                ? "bg-primary-10 hover:bg-primary-20 hover:border-primary-20 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl text-mobile-item-m px-3 py-4"
                : theme === "secondary-m"
                  ? "bg-neutral-0 border-primary-1000 border-[1px] hover:bg-neutral-0 hover:border-primary-80 active:bg-neutral-0 active:border-primary-80 hover:text-primary-80 active:text-primary-80 text-primary-1000 rounded-20 whitespace-nowrap text-mobile-item-m px-4 py-3"
                  : !actived && theme === "ghost"
                    ? " hover:bg-primary-20 hover:border-primary-20 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl"
                   : "bg-primary-10 hover:bg-primary-30 hover:border-primary-30 active:bg-primary-30 active:border-primary-30 text-primary-1000 rounded-2xl"
        } w-full p-4 transition duration-300 ${theme == "footer" ?"sm:text-item-400" : "sm:text-item"}  ${align} sm:whitespace-nowrap text-mobile-item-m`}
      onClick={onClick}
      actived={actived}
    >
        {children}

      </button>
    </NavLink>
  );
};

export default ButtonWebNav;